import { useMemo } from 'react';
import { Transforms } from 'slate';
import { Spinner } from 'app/components';
import { DefaultTable } from 'app/utils/table';
import { ReactEditor, useSlate } from 'slate-react';
import { useEditorSelector } from 'app/state/contexts/EditorContext';

export const ReferenceTable = (props) => {
  const editor = useSlate();
  const { attributes, element, children, diff, side, readOnly } = props;
  const references = useEditorSelector((editor) => editor.references);
  const locale = useEditorSelector((editor) => editor.locale);

  const content = useMemo(() => {
    return references?.find((r) => r.superId === element.referenceDataSuperId);
  }, [references, element]);

  const elementId = `xrp-tableid-${element.uuid}`;

  if (references?.loading) {
    return (
      <div {...attributes}>
        <div style={{ userSelect: 'none' }} contentEditable={false}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (!content) {
    return (
      <div {...attributes}>
        <div style={{ userSelect: 'none' }} contentEditable={false}>
          No data!
        </div>
      </div>
    );
  }

  const onDelete = () => {
    const path = ReactEditor.findPath(editor, props.element);
    Transforms.removeNodes(editor, { at: path });
  };
  const metadata = {
    'data-element-super-id': element.referenceDataSuperId,
    'data-document-revision-id': content.revisionId,
    'data-element-revision-id': `${element.referenceDataSuperId}_${content.revisionId}`,
  };

  return (
    <div className="editor-table--container" {...attributes}>
      {!readOnly && (
        <div className="controls--container">
          <button onClick={onDelete}>
            <i className="fa-regular fa-trash-can" />
          </button>
        </div>
      )}
      <div {...metadata}>
        <DefaultTable id={elementId} data={content} locale={locale} diff={diff} side={side} />
        {children}
      </div>
    </div>
  );
};
