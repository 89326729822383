export const StyledElement = ({ element, children }) => {
  const formatting = element.formatting;

  const metadata = {
    'data-element-super-id': element.uuid,
    'data-document-revision-id': element.revisionId,
    'data-element-revision-id': `${element.uuid}_${element.revisionId}`,
  };

  const superId = `xrp-superid-${element.uuid}`;

  if (!formatting) {
    return <p>{children}</p>;
  }

  const customStyling = {};
  switch (formatting.alignment) {
    case 'align-center':
      customStyling['textAlign'] = 'center';
      break;
    case 'align-justify':
      customStyling['textAlign'] = 'justify';
      break;
    case 'align-right':
      customStyling['textAlign'] = 'right';
      break;
    default:
      break;
  }
  let cssArray = [];
  if (!!formatting.class) {
    cssArray.push(formatting.class);
  }

  if (formatting.indent) {
    cssArray.push('indent');
  }

  const css = cssArray.length === 0 ? undefined : cssArray.join(' ');

  switch (formatting.format) {
    case 'h1':
      return (
        <h1 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h1>
      );
    case 'heading-1':
      return (
        <h1 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h1>
      );
    case 'heading-one':
      return (
        <h1 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h2>
      );
    case 'heading-2':
      return (
        <h2 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h2>
      );
    case 'heading-two':
      return (
        <h2 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h3>
      );
    case 'heading-3':
      return (
        <h3 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h3>
      );
    case 'heading-three':
      return (
        <h3 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h4>
      );

    case 'h5':
      return (
        <h5 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h5>
      );

    case 'h6':
      return (
        <h6 id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </h6>
      );
    case 'blockquote':
      /* <blockquote> is not valid xhtml, use class instead */
      return (
        <div id={superId} style={customStyling} className={`${css} xrp-blockquote`} {...metadata}>
          {children}
        </div>
      );
    case 'paragraph':
      return (
        <p id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </p>
      );
    case 'p':
      return (
        <p id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </p>
      );
    default:
      return (
        <p id={superId} style={customStyling} className={css} {...metadata}>
          {children}
        </p>
      );
  }
};
