export const Leaf = ({ attributes, children, leaf }) => {
  const { bold, code, italic, underline, subscript, superscript } = leaf;
  const {
    parent: { formatting },
  } = children.props;

  let className = '';

  if (bold) {
    if (formatting?.format === 'paragraph' || formatting?.format === 'p') {
      className += 'xrp-body-bold';
    } else if (!formatting?.format) {
      // TODO: GABY-1538: Formatting is undefined in the leaf
      // formatting is undefined, fallback to body as default
      className += 'xrp-body-bold';
    } else {
      className += 'xrp-heading-bold';
    }
  }

  if (italic) {
    if (formatting?.format === 'paragraph' || formatting?.format === 'p') {
      className += 'xrp-body-italic';
    } else if (!formatting?.format) {
      // TODO: GABY-1538: Formatting is undefined in the leaf
      // formatting is undefined, fallback to body as default
      className += 'xrp-body-italic';
    } else {
      className += 'xrp-heading-italic';
    }
  }

  if (underline) {
    className += 'xrp-underline';
  }

  const Wrapper = code ? 'code' : 'span';

  const wrapperProps = {};
  if (subscript) {
    wrapperProps.sub = true;
  }
  if (superscript) {
    wrapperProps.sup = true;
  }

  return (
    <Wrapper {...attributes} className={className} {...wrapperProps}>
      {children}
    </Wrapper>
  );
};
