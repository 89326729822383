import { useQuery, useMutation, useQueryClient } from 'react-query';
import request from 'app/api/request';
import { errorHandler, showMessage } from 'app/utils/messages';
import { i18n } from 'app/utils/i18n';
import { v4 as uuidv4 } from 'uuid';
import { queryKeys as styleQueryKeys } from '../style-queries';

export const queryKeys = {
  documents: 'DOCUMENTS',
  document: 'DOCUMENT',
  versions: 'VERSIONS',
};

export const useGetDocumentVersions = (documentId, documentRevisionId, draftDocument) =>
  useQuery(
    [queryKeys.versions, { documentId, draftDocument, documentRevisionId }],
    () => {
      return request
        .get(`/gaby/versions/${documentId}`)
        .then((res) => res.data)
        .then((versions) => {
          if (!versions.find((d) => d.documentRevisionId === draftDocument.revisionId)) {
            versions.push({
              documentSuperId: draftDocument.superId,
              documentRevisionId: draftDocument.revisionId,
              createdAt: draftDocument.createdAt,
              draft: true,
              name: 'Current draft',
            });
          }

          if (documentRevisionId) {
            versions.find((d) => d.documentRevisionId === documentRevisionId).selected = true;
          } else {
            versions[versions.length - 1].selected = true;
          }

          return versions.reverse();
        });
    },
    { enabled: !!draftDocument }
  );

export const useSectionReorder = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ documentId, sectionOrder }) =>
      request
        .post(`/gaby/documents/${documentId}/actions?compileSass=true`, {
          action: 'set_section_order',
          data: {
            sectionOrder,
          },
        })
        .catch((e) => {
          errorHandler(e);
          throw new Error('');
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.document);
      },
    }
  );
};

export const useGetDocuments = (selectedOrganization, showDeleted, orderBy, orderDirection) =>
  useQuery([queryKeys.documents, { selectedOrganization, showDeleted, orderBy, orderDirection }], () => {
    return request
      .get(`/gaby/documents?current=true&organization=${selectedOrganization}&showDeleted=${showDeleted}&orderBy=${orderBy}&orderDirection=${orderDirection}`)
      .then((response) => response.data)
      .catch((e) => {
        errorHandler(e);
        throw new Error('');
      });
  });

export const useGetDocument = (documentId, selectedOrganization, revisionId, useDraft = false, embedd = false) =>
  useQuery(
    [queryKeys.document, documentId, { selectedOrganization, revisionId }],
    () => {
      const queryString = `organization=${selectedOrganization}&compileSass=true&useDraft=${useDraft}&embedd=${embedd}&showDeleted=true`;
      const path = revisionId ? `/${revisionId}?${queryString}` : `?superId=${documentId}&current=true&${queryString}`;

      return request
        .get(`/gaby/documents${path}`)
        .then((res) => {
          if (res.data.length) {
            const doc = res.data[res.data.length - 1];
            return doc;
          }
          return res.data;
        })
        .catch((e) => {
          errorHandler(e);
          throw new Error('');
        });
    },
    { enabled: !!(selectedOrganization && documentId) }
  );

export const useGetDocumentTableOfContent = (revisionId, { start, stop, currentPosition, sectionSuperId, headerHierarchy, locale } = {}) => {
  const headers = headerHierarchy || ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const params = { start, stop, currentPosition, sectionSuperId, locale, headerHierarchy: headers.join(',') };
  Object.keys(params).forEach((key) => (params[key] === undefined ? delete params[key] : {}));

  const searchParams = new URLSearchParams(params);

  return useQuery({
    queryKey: [queryKeys.document, { revisionId, locale, start, stop, currentPosition, sectionSuperId, headerHierarchy }],
    queryFn: () => {
      if (revisionId === null) {
        return Promise.resolve(null);
      }
      return request
        .get(`/gaby/documents/${revisionId}/table-of-content?${searchParams}`)
        .then((res) => res.data)
        .catch((e) => {
          errorHandler(e);
          throw new Error('');
        });
    },
    keepPreviousData: true,
  });
};

export const useCreateTemplateStyle = (documentId) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ template }) => {
      const newStyle = {
        id: uuidv4(),
        documentSuperId: documentId,
        content: template.style,
      };

      await request.post(`/gaby/styles`, newStyle);

      const response = await request.post(`/gaby/documents/${documentId}/actions?compileSass=true`, {
        action: 'create_template_style',
        revisionId: newStyle.id,
        data: {
          styleId: newStyle.id,
          templateSuperId: template.superId,
          templateRevisionId: template.revisionId,
        },
      });

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(styleQueryKeys.css);
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );
};

export const useCreateFontStyle = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ documentSuperId, fileSuperId }) => {
      return request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'create_font_style',
        data: {
          fileSuperId,
        },
      });
    },
    {
      onSuccess: (_, { documentSuperId }) => {
        showMessage('success', i18n('project-details.font-created'));
        queryClient.invalidateQueries(queryKeys.document, documentSuperId);
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );
};

export const useCloneDocument = () =>
  useMutation(
    ({ documentSuperId, documentRevisionId, selectedOrganization }) => {
      return request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'clone_document',
        organization: selectedOrganization,
        revisionId: documentRevisionId,
        superId: documentSuperId,
      });
    },
    {
      onSuccess: () => {
        showMessage('success', i18n('project-details.document-copy-success'));
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );

export const usePageNumberingSave = () =>
  useMutation(
    ({ documentSuperId, documentRevisionId, pageNumbering }) =>
      request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'update_document_page_numbering',
        data: {
          revisionId: documentRevisionId,
          superId: documentSuperId,
          pageNumbering,
        },
      }),
    {
      onError: (error) => {
        errorHandler(error);
      },
    }
  );

export const useDocumentAreaSave = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ documentSuperId, documentRevisionId, header, footer }) =>
      request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'update_document_area',
        data: {
          revisionId: documentRevisionId,
          superId: documentSuperId,
          header,
          footer,
        },
      }),

    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [queryKeys.document] });
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );
};

export const useDocumentMetadataSave = () =>
  useMutation(
    ({ documentSuperId, documentRevisionId, metadata }) =>
      request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'update_document_metadata',
        data: {
          revisionId: documentRevisionId,
          superId: documentSuperId,
          metadata,
        },
      }),
    {
      onError: (error) => {
        errorHandler(error);
      },
    }
  );

export const useChangeDocumentName = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ documentSuperId, newName }) => {
      return request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'update_document_name',
        newName: newName,
      });
    },
    {
      onSuccess: () => {
        showMessage('success', i18n('project-list.document-rename-success'));
        queryClient.invalidateQueries(queryKeys.documents);
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );
};

export const useDeleteDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ documentSuperId, selectedOrganization }) => {
      return request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'delete_document',
        organization: selectedOrganization,
      });
    },
    {
      onSuccess: () => {
        showMessage('success', i18n('project-list.document-delete-success'));
        queryClient.invalidateQueries(queryKeys.documents);
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );
};

export const useRestoreDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ documentSuperId, selectedOrganization }) => {
      return request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'restore_document',
        organization: selectedOrganization,
      });
    },
    {
      onSuccess: () => {
        showMessage('success', i18n('project-list.document-restore-success'));
        queryClient.invalidateQueries(queryKeys.documents);
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );
};

export const useExportDocument = () =>
  useMutation(
    ({ documentSuperId, documentRevisionId, selectedOrganization }) => {
      return request
        .post(`/gaby/documents/${documentSuperId}/actions`, {
          action: 'export_document',
          organization: selectedOrganization,
          revisionId: documentRevisionId,
          superId: documentSuperId,
        })
        .then((res) => {
          const data = res.data;
          const element = document.createElement('a');
          element.setAttribute('href', `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`);
          element.setAttribute('download', `${data.document.name}.json`);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
    },
    {
      onSuccess: () => {
        showMessage('success', i18n('project-details.document-export-success'));
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );

export function useNewDocument() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ documentName, selectedOrganization }) => {
      return request.post(`/gaby/documents`, {
        organization: selectedOrganization,
        name: documentName,
      });
    },
    {
      onSuccess: (_, { selectedOrganization }) => {
        showMessage('success', i18n('project-details.document-create-success'));
        queryClient.invalidateQueries(queryKeys.documents, { selectedOrganization });
      },
      onError: (error) => {
        errorHandler(error);
      },
    }
  );
}
