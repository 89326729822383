import React, { useState } from 'react';
import { BlockButton } from './BlockButton';
import { MarkButton } from './MarkButton';
import Tippy from '@tippyjs/react';
import { LanguageSelector } from 'app/components';
import { Modal, Dropdown } from 'app/components';
import { ImportImage, ImportTable, ImportReference, ImportToC } from 'app/components/editor/import-area';
import { i18n } from 'app/utils/i18n';
import { useHistory } from 'react-router-dom';
import { MarkHeadingDropdown } from './MarkHeadingDropdown';
import { useEditorSelector } from 'app/state/contexts/EditorContext';

const StylingComponent = () => {
  return (
    <>
      <div className="d-flex align-items-center">
        <MarkHeadingDropdown />
      </div>
      <div className="divider" />
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.bold')}</p>} placement="bottom">
        <MarkButton format="bold" icon="bold" text="Bold" css="toolbar-btn" />
      </Tippy>
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.italic')}</p>} placement="bottom" theme="light">
        <MarkButton format="italic" icon="italic" text="Italic" css="toolbar-btn" />
      </Tippy>
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.underline')}</p>} placement="bottom" theme="light">
        <MarkButton format="underline" icon="underline" text="Underlined" css="toolbar-btn" />
      </Tippy>
      <div className="divider" />
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.align-left')}</p>} placement="bottom" theme="light">
        <BlockButton format="custom-formatting" icon="align-left" styling={{ alignment: 'align-left' }} text="Align left" css="toolbar-btn" />
      </Tippy>
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.align-center')}</p>} placement="bottom" theme="light">
        <BlockButton format="custom-formatting" styling={{ alignment: 'align-center' }} icon="align-center" text="Align center" css="toolbar-btn" />
      </Tippy>
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.align-justify')}</p>} placement="bottom" theme="light">
        <BlockButton format="custom-formatting" styling={{ alignment: 'align-justify' }} icon="align-justify" text="Align justify" css="toolbar-btn" />
      </Tippy>
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.align-right')}</p>} placement="bottom" theme="light">
        <BlockButton format="custom-formatting" styling={{ alignment: 'align-right' }} icon="align-right" text="Align right" css="toolbar-btn" />
      </Tippy>
      <div className="divider" />
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.list-ol')}</p>} placement="bottom" theme="light">
        <BlockButton format="numbered-list" icon="list-ol" text="Ordered list" css="toolbar-btn" />
      </Tippy>
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.list-ul')}</p>} placement="bottom" theme="light">
        <BlockButton format="bulleted-list" icon="list-ul" text="Unordered list" css="toolbar-btn" />
      </Tippy>
      <div className="divider" />
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.indent')}</p>} placement="bottom" theme="light">
        <BlockButton format="custom-formatting" styling={{ indent: true }} icon="indent" text="indent" css="toolbar-btn" />
      </Tippy>
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.blockquote')}</p>} placement="bottom" theme="light">
        <BlockButton format="custom-formatting" styling={{ format: 'blockquote' }} icon="quote-left" text="Block quote" css="toolbar-btn" />
      </Tippy>
      <div className="divider" />
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.superscript')}</p>} placement="bottom" theme="light">
        <MarkButton format="superscript" icon="superscript" text="Superscript" css="toolbar-btn" />
      </Tippy>
      <Tippy content={<p className="gaby-tooltip arrow-top">{i18n('sub-toolbar.tooltip.subscript')}</p>} placement="bottom" theme="light">
        <MarkButton format="subscript" icon="subscript" text="Subscript" css="toolbar-btn" />
      </Tippy>
    </>
  );
};

const InsertComponent = ({ setSelected, selected }) => {
  const items = [
    { title: i18n('toolbar.dropdown.insert-image'), icon: <i className="fa-regular fa-image" />, value: 'select_image' },
    { title: i18n('toolbar.dropdown.insert-list'), icon: <i className="fa-regular fa-table" />, value: 'select_table' },
    { title: i18n('toolbar.dropdown.insert-reference'), icon: <i className="fa-regular fa-link" />, value: 'select_reference' },
    { title: i18n('toolbar.dropdown.insert-toc'), icon: <i className="fa-regular fa-list-ol" />, value: 'select_toc' },
  ];
  const reduxActiveNode = useEditorSelector((editor) => editor.activeNode);

  return (
    <>
      <div className="divider" />
      <div className="d-flex align-items-center">
        <Dropdown sm onChange={setSelected} items={items} value={selected}>
          <Dropdown.DropdownButton
            title={i18n('sub-toolbar.dropdown.insert.title')}
            disabled={Object.keys(reduxActiveNode).length === 0}
            sm
            icon={
              <div
                className="bg-blue-300 text-blue-700 rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: '1.25rem', height: '1.25rem' }}
              >
                <i className="fa-regular fa-plus" />
              </div>
            }
          />
        </Dropdown>
      </div>
    </>
  );
};

const LanguangeComponent = () => {
  const history = useHistory();
  const isDirty = useEditorSelector((editor) => editor.isDirty);
  const languages = useEditorSelector((editor) => editor.languages);
  const locale = useEditorSelector((editor) => editor.locale);
  const onChange = async (selectedLocale) => {
    const translation = languages.find((lang) => lang.locale === selectedLocale);
    if (translation && !translation.isDefault) {
      history.push(`?translation=${translation.superId}`);
    } else {
      history.push(`?`);
    }
    window.location.reload();
  };
  return (
    <div className="d-flex align-items-center">
      <LanguageSelector sm onChange={onChange} locale={locale} disabled={isDirty} />
    </div>
  );
};

export const SubToolbar = ({ enabled, shadowedChildren }) => {
  const [currentModal, setCurrentModal] = useState();

  return (
    <div className="sub-toolbar space-x-3">
      {!!enabled.styling ? <StylingComponent /> : null}
      {!!enabled.insert ? <InsertComponent setSelected={setCurrentModal} selected={currentModal} /> : null}
      {!!enabled.languange ? <LanguangeComponent /> : null}
      <Modal show={currentModal === 'select_image'} onClose={setCurrentModal}>
        <ImportImage onClose={setCurrentModal} />
      </Modal>
      <Modal show={currentModal === 'select_table'} onClose={setCurrentModal}>
        <ImportTable onClose={setCurrentModal} />
      </Modal>
      <Modal show={currentModal === 'select_reference'} onClose={setCurrentModal}>
        <ImportReference onClose={setCurrentModal} />
      </Modal>
      <Modal titleLabelKey="toc.insert.modal-title" size="lg" show={currentModal === 'select_toc'} onClose={setCurrentModal}>
        <ImportToC onClose={setCurrentModal} />
      </Modal>
      {shadowedChildren}
    </div>
  );
};
